@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --blue: #5A8EFF;
  --lightBlue: #EFF4FF;
  --red: #EC483F;
  --lightRed: #FDEDEC;
  --green: #44B97D;
  --lightGreen: #ECF8F2;
  --yellow: #FABC2A;
  --lightYellow: #FAF2E1;
  --white: #ffffff; 
  --black: #000000;
}

h1 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 48px;
  color: var(--black);
  margin: 0px;
}

h2 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 24px;
  color: var(--blue);
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
}

h3 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
}

p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--black);
}

select {
  height: 56px;
  width: 100%;
  background: var(--lightBlue);
  border: 0px;
  border-radius: 8px;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
}

table {
  text-align: center;
}

.text-input {
  height: 56px;
  width: 100%;
  background: var(--lightBlue);
  border: 0px;
  border-radius: 8px;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid var(--blue);  
}

.button {
  height: 42px;
  display: flex;
  color: var(--white);
  border: none;
  outline: none;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: var(--blue);
  border-radius: 8px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  cursor: pointer;
}

.button.left {
  margin-left: 0px;
}

.button.red {
  background: var(--red);
}

.button:hover {
  opacity: 75%;
}

.button:disabled {
  opacity: .75;
}

.button-row {
  display: flex;
  flex-direction: row;
  column-gap: 14px;
}

.error-txt {
  color: var(--red);
}


.app-container {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background-color: var(--white);
  min-width: 1350px;
}

.navbar-cont {
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.logo-image {
  height: 48px;
}

.link {
  padding-left: 50px;
}

.link:hover {
  text-decoration: underline;
}

.pill-button {
  padding: 5px 15px 5px 15px;
  border-radius: 30px;
  color:var(--blue);
  background-color: var(--lightBlue);
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 13px;
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: auto;
  cursor: pointer;
  
}
.pill-button:hover {
  opacity: 75%;
}

.pill-button.stack {
  padding: 5px 15px 5px 15px;
  border-radius: 30px;
  color:var(--blue);
  background-color: var(--lightBlue);
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 13px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: auto;
}

.pill-button.red {
  color:var(--red);
  background-color: var(--lightRed);
}

.pill-button.yellow {
  color:var(--yellow);
  background-color: var(--lightYellow);
}

.pill-button.outline {
  border: var(--blue) solid 2px
}

td {
  padding-bottom: 2px;
}

.inline-pill-button {
  display: inline-flex;
  flex-direction: row;
  gap: 2px;
  padding: 3px 6px;
  margin-left: 8px;
  background-color: var(--blue);
  color: white;
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: none;
}

.inline-pill-button:hover {
  opacity: 0.5;
}

.columns-container {
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.full-column-cont {
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}


.full-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--lightBlue);
  border-radius: 15px;
  padding: 15px;
  margin: 15px;
}


.twothird-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 70%;
  height: 70vh;
  padding: 30px;
  margin: 15px;
  background-color: var(--lightBlue);
  border-radius: 15px;
}

.third-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 70vh;
  padding: 30px;
  margin: 15px;
  background-color: var(--lightBlue);
  border-radius: 15px;
}
.half-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  height: 70vh;
  padding: 30px;
  margin: 15px;
  background-color: var(--lightBlue);
  border-radius: 15px;
}

.column-footer {
  flex-direction: row;
  margin-top: auto;
  align-self: end;
}

.titles-cont {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  background-color: var(--lightBlue);
}

.third-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  padding: 15px;
  width: 30%;
  margin: 15px;
  background-color: var(--lightBlue);
  border-radius: 10px;
  border: 1px solid var(--blue);  
  height: fit-content;
}

.box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.box-stats-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.box-stat {
  margin-right: 20px;
}

.card-list-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.card-container {
  margin-top: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 35px);
  border: 1px solid var(--blue);
  border-radius: 10px;
  height: fit-content;
}

.card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.card-indicator {
  padding: 5px 15px 5px 15px;
  border-radius: 30px;
  margin-top: -2px;
  color:var(--red);
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 13px;
  margin-left: auto;
}

.card-indicator.green {
  background-color: var(--lightGreen);
  color:var(--green);
  font-family: 'Poppins';
}

.card-indicator.amber {
  background-color: var(--lightYellow);
  color: var(--yellow);
  font-family: 'Poppins';
}

.card-indicator.red {
  background-color: var(--lightRed);
  color:var(--red);
  font-family: 'Poppins';
}

.modal-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

.modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  background-color: var(--white);
  padding: 15px;
  row-gap: 15px;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.forms-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.button-card-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 16px;
  max-width: 1507px;
  box-sizing: border-box;
}

.button-card {
  display: flex;
  background-color: var(--lightBlue);
  width: auto;
  margin-left: auto;
  padding: 15px;
  border-radius: 15px;
}

.login-cont {
  display: flex;
  height: 100vh;
  margin: auto;
  margin-top: -100px;
  width: 400px;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.users-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--lightBlue);
  padding: 15px;
  border-radius: 15px;
}

.search-window {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0px;
  row-gap: 15px;
  width: 100%;
}